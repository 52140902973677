import UserObject from "../objects/UserObject";
import {CaseList} from "./Cases/CaseList";
import CaseObject from "../objects/CaseObject";
import {CaseBriefing} from "./Cases/CaseBriefing";

export function Front() {

    if (CaseObject.casePicked()) return CaseBriefing();//(<>{CaseList}</>);
    if (UserObject.getAuthed()) return CaseList();//(<>{CaseList}</>);

    return (
        <>
            <div className="logowrapper"><img className="logo" src="images/logo_scrambled_highres_white-2.png"/></div>

           Welcome to RE:Solver GO, an experimental pocket-size version of REsolver.

            <br/>
            <br/>
            In this game, you will try out the main game loop of REsolver, where you gather information and ask questions.
            <br/>
            <br/>
            This is an experimental version, so guests at the SeijoCon are the first to see the game in this light.<br/>
            That also means that if you have any question, you can always visit our booth in the convention area if you have questions, feedback or concerns.<br/>
            We will also be hosting some "traditional" rounds of REsolver at the convention, visit the booth for specific times.
            <br/>
            <br/>
            <b className="text-warning">Prototype warning!</b> RE:Solver is normally played as a tabletop RPG, so doing this is still a fresh experiment.
            <br/>Technical issues will probably occur during the convention, and the experience could use some quality of life upgrades.
            <br/>
        </>
    );
}
