export function Rules() {
    return (
        <>

            <div className="rule-set">
            <h2>Game Rules:</h2>
            <ul>
                <li>This game can be played as a group or by yourself.</li>
                <li>There is NO time pressure in this version of the game. You play when you want, when you can.</li>
                <li>If you have questions, ask the Game Master.</li>
                <li>Please remember this version of the game is an early prototype. Technical issues or quality of life improvements are on their way.</li>
                <li>You don't have to download anything, and if you wish, your account will be deleted at the end of the convention.</li>
                <li><b>Sharing and teaming up:</b></li>
                <ul>
                    <li>Don't spoil the elements of this game for others.</li>
            <li>Please only share information with others if they agree to share.</li>
            <li>Please don't shout solutions out loud.</li>
            <li>Let others play at their own pace. It's not a race.</li>
                    <li>When you have the answer, please discretely inform the Game Master. He will have a final form for you to fill out.</li>
                </ul>
            <li><b>Questions:</b></li>
            <ul>
                <li>Only ask one thing per question.</li>
                <li>Please be as clear and precise as possible what information you need.</li>
            <li>Please let the Game Master have 10 minutes or so to research the answer once you ask.</li>
            <li>You can ask in Danish if English is hard for you.</li>
                <li>If you want a printed version of an answer, visit our booth in the convention area.</li>
            </ul>
                <li>The game and the cases in it is all fictional; nothing here is real.</li>
                <li>Any resemblance to real life events, places or individuals is entirely coincidental.</li>
            </ul>

            <h2>Tips for Successful Gameplay:</h2>
            <ul>
            <li>Be curious.</li>
            <li>Don't be too aggressive when dealing with the suspects; that might just spook them.</li>
            <li>You are in charge of your own moral compass. If you need the dark web to get someone's credit card details to solve a case, go for it.</li>
            <li>When you hear hooves, think horses not zebras. Sometimes overthinking things can lead down a rabbit hole.</li>
            <li>This is NOT an inside job. The detectives and experts are doing their best.</li>
            </ul>

            <h2>Content Warnings:</h2>
            <ul>
            <li>The cases and mysteries presented in these cases may touch upon sensitive and heavy subjects.</li>
            <li>Players should be aware that the game might explore themes such as violence, death, self harm, abuse, drugs, and other emotionally challenging topics.</li>
                <li>At the convention booth, the Game Master will have a list of content warnings associated with each case.</li>
                <li>If you feel uncomfortable with any elements of the game, stop playing.</li>
            </ul>


            </div>
        </>
    );
}
