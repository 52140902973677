import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import CaseObject from "../../objects/CaseObject";
import ServerHandler from "../../objects/ServerHandler";
import parse from 'html-react-parser'


export function CaseBriefing() {
    let briefingText = "Loading text";
    let [briefText, setBriefText] = useState<string>("Loading...");

    useEffect(() => {
        loadBriefing();
    }, []);

    function loadBriefing() {
        axios.get(ServerHandler.host()+"/my/case/"+CaseObject.getCaseId()+"/briefing",
            UserObject.getUserAuth()).then(response => {
                setBriefText(response.data.briefing);
            }).catch(function(error) {
                console.log(error);
            });
        }

    return (
        <>
        <h1>Case briefing</h1>
            {parse(briefText)}
        </>
    );
}
