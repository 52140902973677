import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import App from "../../App";
import CaseObject from "../../objects/CaseObject";
export function CaseFocus() {

    let { case_id } = useParams();

    CaseObject.setCaseInviteId(case_id);
    window.location.href = '/';

    return (
        <>
            Case Focus {case_id}
        </>
    );
}
