import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import {AxiosResponse} from "axios";

var UserObject = (function() {
    var full_name = "test";

    var getName = function() {
        return getCookie('name') ;    // Or pull this from cookie/localStorage
    };
    var getUserAuth = function() {
        return {headers:{
                user: getCookie('id'), token: getCookie('token')
            }};    // Or pull this from cookie/localStorage
    };

    var setUserPackage = function(response : AxiosResponse) {
        full_name = response.data.name;
        setCookie('name', response.data.name);
        setCookie('id', response.data.id);
        setCookie('token', response.data.token);
    };

    var getAuthed = function() {
        let usertoken : string | undefined = getCookie('token');
        return typeof usertoken == "string" && usertoken.length > 0;
    }

    var unsign = function() {
        removeCookie('name');
        removeCookie('token');
    }

    return {
        getName: getName,
        setUserPackage: setUserPackage,
        getAuthed: getAuthed,
        getUserAuth: getUserAuth,
        unsign : unsign
    }

})();

export default UserObject;