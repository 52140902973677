import React, { useState } from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import ServerHandler from "../../objects/ServerHandler";
import { redirect  } from "react-router-dom";


export function Register() {
    function accountCreate(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            username: { value: string };
            password: { value: string };
        };

        axios.post(ServerHandler.host()+"/auth/createaccount",
            {
                username: target.username.value,
                password: target.password.value
            }).then(response => {

                if (typeof response.data.name != "undefined") {
                    UserObject.setUserPackage(response);
                    window.location.href = "/my/cases";
                } else {
                    alert(typeof response.data.name);
                }
        }).catch(function(error) {
            console.log(error);
        });
    }



    return (
        <>
            <h1>Register new account</h1>
            <form onSubmit={accountCreate}>
                <div>
                    <label>
                        Username:
                        <input className="blockinput" type="name" name="username" />
                    </label>
                </div>
                <div>
                    <label>
                        Password:
                        <input className="blockinput" type="password" name="password" />
                    </label>
                </div>

                <div>
                    <label>
                        Clearance:<br/>
                        <input className="blockcheckbox" type="checkbox" name="delete" /> Delete my account when SeijoCon is over.
                    </label>
                </div>

                <button className="blockbutton" type="submit">Create account</button>
            </form>
        </>
    );
}
