import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import { ColumnDef } from '@tanstack/react-table';
import {Table} from "../../interfaces/Table";
import {Link} from "react-router-dom";
import ServerHandler from "../../objects/ServerHandler";

type CaseListItem = {
    id : string;
    name: string;
}

export function CaseList() {

    let items : CaseListItem[] = [];
    const [itemList, setItemList] = useState<CaseListItem[]>();
    let loading = true;

    useEffect(() => {
        loadCases();
    }, []);

    function registerCase(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            casekey: { value: string };
        };

        axios.post(ServerHandler.host()+"/my/cases/register",
            {
                casekey: target.casekey.value
            },UserObject.getUserAuth()).then(response => {

            loadCases();
            if (typeof response.data.error != "undefined") {
                alert(response.data.error);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }
    function loadCases() {
        axios.get(ServerHandler.host()+"/my/cases",UserObject.getUserAuth()).then(response => {
            items = [];
            for (let item of response.data.invites) {
                items.push({
                    id: item.id,
                    name: item.case.title
                });
            }
            setItemList(items);
            loading = false;
           // window.location.reload();
        }).catch(function(error) {
            console.log(error);
        });
    }


    const cols = useMemo<ColumnDef<CaseListItem>[]>(
        () => [
            {
                header: 'Name',
                cell: (row) => row.renderValue(),
                accessorKey: 'name'
            },
            {
                header: 'Link',
                cell: ({row}) => (<Link className="btn btn-info btn-sm" to={{ pathname: `/my/case/${row.original.id}` }}>Open case</Link>),
                filterable : true
            },
        ],
        []
    );


    return (
        <>

            {!itemList && <>
                Loading cases...
            </>}

            {itemList && itemList.length === 0 &&
                <>
                    <h1>Welcome to RE:Solver</h1>
                    <div className="">
                        You are now allowed into our inner circle of freelance detectives, and ready to be assigned to your new case.
                    </div>
                    <div className="">
                        Please contact your RE:Solver handler to get your first case.
                        <br/><br/>
                        Each case is assigned individually, but other people in your area might be working on the same case as you.
                        <br/><br/>It is up to you if you want to involve other players, or just work on the case alone.
                    </div>
                </>
            }



            {itemList && itemList.length > 0 &&
                <>
            <h1>Case list</h1>
            <div className="">
                <Table data={itemList} columns={cols}/>
            </div>
                </>
            }


            <button className="hidden btn btn-info btn-xs" onClick={loadCases}>Reload</button>


            {itemList && itemList.length === 0 &&
                <>
            <h3>Connect to case:</h3>
            <form onSubmit={registerCase}>
                <div>
                    <label>
                        Six-digit case key:
                        <input type="name" name="casekey" placeholder="••••••"/>
                    </label>
                </div>

                <button className="btn btn-primary" type="submit">Check in</button>
            </form></>
            }
        </>
    );
}
