import React from "react";
import CaseObject from "../../objects/CaseObject";
export function CaseExit() {

    CaseObject.unsign();
    window.location.href = '/';

    return (
        <>
            Case closed
        </>
    );
}
