import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import CaseObject from "../../objects/CaseObject";
import ServerHandler from "../../objects/ServerHandler";

export function CaseDocuments() {
    let [briefText, setBriefText] = useState<string>("Loading...");

    useEffect(() => {
        //loadBriefing();
    }, []);


    return (
        <>
            <h1>Case documents</h1>
            <>Documents TBD</>
        </>
    );
}
