import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import CaseObject from "../../objects/CaseObject";
import CSS from 'csstype';
import ServerHandler from "../../objects/ServerHandler";
import parse from "html-react-parser";

type AskPerson = {
    id : string;
    name: string;
    category: string;
    shortkey : string;
    summary: string;
    face: string;
    updates : number;
}
type ChatMessage = {
    id : string;
    question: string;
    answer: string;
}

export function AskQuestion() {
    const [peopleList, setPeopleList] = useState<AskPerson[]>([]);
    const [chatLog, setChatLog] = useState<ChatMessage[]>([]);
    const [focusPerson, setFocusPerson] = useState<AskPerson>();
    const [mayAsk, setMayAsk] = useState<boolean>(false);

    let loading = false;
    function switchFocus(person : AskPerson) {
        if (focusPerson == person) return;
        setFocusPerson(person);
        loadLog(person.id);
        setChatLog([]);
    }

    function unfocusPerson() {
        setFocusPerson(undefined);
    }

    useEffect(() => {
        if (peopleList.length === 0) loadPeople();

        // Start the recurring check using setInterval
        const interval = setInterval(() => {
            if (document.visibilityState === "visible") {
                // Call loadPeople only if the page is visible
                loadPeople();
            }
        }, 15000);

    }, []);

    function loadLog(personId : string) {
        axios.get(ServerHandler.host()+"/my/case/"+CaseObject.getCaseId()+"/log/"+personId,
            UserObject.getUserAuth()).then(response => {
            let questions : ChatMessage[] = [];
            for (let item of response.data.questions) {
                questions.push({
                    id: item.id,
                    question: item.question,
                    answer: item.answer,
                });
            }
            setChatLog(questions);
            setMayAsk(response.data.mayask);
        }).catch(function(error) {
            console.log(error);
        });
    }
    function loadPeople() {
        if (loading) return;
        loading = true;
        axios.get(ServerHandler.host()+"/my/case/"+CaseObject.getCaseId()+"/questionables",
            UserObject.getUserAuth()).then(response => {
            let people : AskPerson[] = [];
            for (let item of response.data.people) {
                people.push({
                    id: item.id,
                    name: item.charactername,
                    category: item.category,
                    summary: item.summary,
                    shortkey : item.shortkey,
                    face: item.face,
                    updates : item.newcount
                });
            }
            setPeopleList(people);
            loading = false;
        }).catch(function(error) {
            console.log(error);
            loading = false;
        });
    }

    function peopleButtonList(list : AskPerson[], category: string) {
        return list.map(function (person) {
            if (person.category != category) return;
            return (
                <>
                    <div><a className="btn btn-npc"
                            onClick={() => switchFocus(person)}>{person.shortkey}
                        {person.updates !== 0 && <b className="badge badge-pill badge-dark float-right">{person.updates}</b>}
                    </a></div>
                </>);
        });
    }

    function printChatLog(list : ChatMessage[]) {
        let response = list.map(function (log) {
            if (log.answer === "none") {
                return (
                    <>
                        <div style={messageQuestion}><b>{log.question}</b></div>
                        <div style={messageWaiting}><i>The character will respond to your question soon. (Eta. 2-5 minutes)</i></div>
                    </>);
            }
            return (
                <>
                    <div style={messageQuestion}><b>{log.question}</b></div>
                    <div style={messageAnswer}><i>{parse(log.answer)}</i></div>
                </>);
        });
        return response;
    }

    function askQuestion(e: React.SyntheticEvent) {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            question: { value: string };
            ask_target: { value : string}
        };

        axios.post(ServerHandler.host()+"/my/case/"+CaseObject.getCaseId()+"/ask/"+target.ask_target.value,
            {
                question: target.question.value
            }).then(response => {
            loadLog(target.ask_target.value);
            target.question.value = "";
        }).catch(function(error) {
            console.log(error);
        });
    }


    const faceStyle : CSS.Properties = {
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        display:'block',
        width: '100%',
    };
    const questionBox : CSS.Properties = {
        backgroundColor: 'rgba(155, 155, 155, 0.85)',
        position: 'fixed',
        right: 0,
        left:0,
        bottom: 0,
        padding:'20px'
    };

    const messageQuestion : CSS.Properties = {
        backgroundColor: '#08C4AB',
        display:'block',
        padding:'10px',
    };
    const messageAnswer : CSS.Properties = {
        border: '1px dotted #b2caf6',
        borderTopWidth : '0',
        color: '#b2caf6',
        textAlign: 'right',
        display:'block',
        padding:'10px',
        marginBottom:'10px'
    };
    const messageWaiting : CSS.Properties = {
        border: '1px dotted grey',
        borderTopWidth : '0',
        color: 'grey',
        textAlign: 'center',
        display:'block',
        padding:'10px',
        marginBottom:'10px'
    };

    return (
        <>

            <div className="row bottom-buffer">
                {!focusPerson &&
                <><div className="col-md-3" data-people={peopleList.length}>
                    <>
                        <h3>Experts:</h3>{peopleButtonList(peopleList,"expert")}
                        <h3>Prime Suspects:</h3>{peopleButtonList(peopleList,"primesuspect")}
                        <h3>Secondary Suspects:</h3>{peopleButtonList(peopleList,"secondsuspect")}
                        <h3>Others:</h3>{peopleButtonList(peopleList,"irrelevant")}
                        <h3>Universal:</h3>{peopleButtonList(peopleList,"universal")}
                    </>
                </div></>
                }


                        {focusPerson &&
                            <>
                            <div className="col-md-9">
                            <h2><b className="BackSlide" onClick={unfocusPerson}>&lt;</b> {focusPerson?.name}</h2>
<div>{printChatLog(chatLog)}</div>
                            </div>
                                {chatLog.length != 0 && <div className="smallbuffer"></div>}
                                <div className="col-md-3">
                                    <img src={focusPerson.face} className="personPhoto" style={faceStyle}/>
                                    <div>{focusPerson?.summary}</div>

                                    <div className="bufferpanel"></div>

                                    {mayAsk &&
                                        <>
                                <form style={questionBox} onSubmit={askQuestion}>
                                            Ask {focusPerson?.name} a question:
                                            <textarea name="question" className="blockinput"/>
<input type="hidden" name="ask_target" value={focusPerson?.id}/>
                                    <button className="btn blockbutton btn-sm" type="submit">Submit question</button>
                                </form>
                                        </>
                                    }

                            </div>

                        </>
                        }
                        {!focusPerson &&
                        <>
                        <div className="col-md-9">
                            <h1>Ask a question</h1>
                            Pick a person from the sidebar.
                        </div>
</>
                    }

            </div>
        </>
    );
}
