import React, {useReducer, useState} from 'react';
import {Routes, Route, Link} from "react-router-dom";
import {Front} from "./pages/Front"
import {Rules} from "./pages/Rules"
import {Testing} from "./pages/Testing"
import UserObject from "./objects/UserObject";
import {Logout} from "./pages/Auth/Logout";
import {Register} from "./pages/Auth/Register";
import {Login} from "./pages/Auth/Login";
import {CaseList} from "./pages/Cases/CaseList";
import {CaseFocus} from "./pages/Cases/CaseFocus";
import CaseObject from "./objects/CaseObject";
import {CaseExit} from "./pages/Cases/CaseExit";
import {AskQuestion} from "./pages/Questions/AskQuestion";
import {CaseDocuments} from "./pages/Cases/CaseDocuments";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';



export function App() {
/*
    const beamsClient = new PusherPushNotifications.Client({
        instanceId: "6a21fcf4-a422-4e32-8e19-d0277f2546e0",
    });


 */

    const firebaseConfig = {
        apiKey: "AIzaSyBbEYLzHfWYhIWj-hOh1ACWCr9PuZoBEeA",
        authDomain: "resolver-app-24b53.firebaseapp.com",
        projectId: "resolver-app-24b53",
        storageBucket: "resolver-app-24b53.appspot.com",
        messagingSenderId: "961318456758",
        appId: "1:961318456758:web:83394be5d95437a6b50517",
        measurementId: "G-LRDWJRYR9E"
    };

    const firebaseApp = initializeApp(firebaseConfig);

    const messaging = getMessaging(firebaseApp);

    const requestNotificationPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // Now you can subscribe the user to a topic or get the registration token
            } else {
                console.log('Notification permission denied.');
            }
        } catch (error) {
            console.log('Error getting notification permission:', error);
        }
    };

    if (UserObject.getAuthed()) {
        requestNotificationPermission();
        /*
        beamsClient.start()
            .then((client) => beamsClient.getDeviceId())
            .then((deviceId) =>
                console.log("Successfully registered with Beams. Device ID:", deviceId)
            )
            .then(() => beamsClient.addDeviceInterest("hello"))
            .then(() => beamsClient.getDeviceInterests())
            .then((interests) => console.log("Current interests:", interests));

         */
    }
    onMessage(messaging, (payload) => {
        console.log('Notification received:', payload);
        // Handle the notification, e.g., show a notification in your app
    });

    return (
      <>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="" id="navbarNav" data-oldclass="collapse navbar-collapse">
              {UserObject.getAuthed() ? (

                <>
                    {CaseObject.casePicked() ? (
                        <>
                            <ul className="navbar-nav">
                                <li><Link className="nav-link" to="/my/case">Case</Link></li>
                                <li><Link className="nav-link" to="/rules">Rules</Link></li>
                                <li><Link className="nav-link hidden" to="/my/case/documents">Documents</Link></li>
                                <li><Link className="nav-link" to="/my/case/ask">Ask questions</Link></li>
                                <li><Link className="nav-link" to="/my/cases/exit">Exit case</Link></li>
                            </ul>
                        </>
                        ) : (
                        <>
                        <ul className="navbar-nav">
                        <li><Link className="nav-link" to="/my/cases">Cases</Link></li>
                        <li><Link className="nav-link" to="/auth/logout">Log out</Link></li>
                        </ul></>
                    )}
                    <span className="navbar-brand d-none d-sm-none d-sm-none d-md-block float-right">Agent {UserObject.getName()}</span>
                </>
              ) : (
                  <>
                      <span className="navbar-brand  d-none d-sm-none d-sm-none d-md-block">RE:Solver</span>
                  <ul className="navbar-nav">
                      <li><Link className="nav-link" to="/">Home</Link></li>
                      <li><Link className="nav-link" to="/rules">Rules</Link></li>
                  </ul>
                  <ul className="navbar-nav float-right">
                    <li><Link className="nav-link" to="/auth/login">Login</Link></li>
                    <li><Link className="nav-link" to="/auth/register">Create account</Link></li>

                  </ul>
                  </>
              )}
              </div>
          </nav>
      <Routes>
          <Route path="/" element={<Front/>}/>
          <Route path="/rules" element={<Rules/>}/>
          <Route path="/test" element={<Testing/>}/>
          <Route path="/auth/logout" element={<Logout/>}/>
          <Route path="/auth/register" element={<Register/>}/>
          <Route path="/auth/login" element={<Login/>}/>
          <Route path="/my/cases" element={<CaseList/>}/>
          <Route path="/my/case" element={<Front/>}/>
          <Route path="/my/cases/exit" element={<CaseExit/>}/>
          <Route path="/my/case/:case_id" element={<CaseFocus/>}/>
          <Route path="/my/case/ask" element={<AskQuestion/>}/>
          <Route path="/my/case/documents" element={<CaseDocuments/>}/>
      </Routes>
      </>
  );
}

export default App;
