import React, { useState } from "react";
import axios from "axios";
import UserObject from "../objects/UserObject";
import App from "../App";


export function Testing() {
    //const [name, setName] = useState([]);

 function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    axios.get("http://crime.test/api/guess/auth/hello").then(response => {
        alert(response.data);
    }).catch(function(error) {
        console.log(error);
    });
}

 function authTest(e: React.SyntheticEvent) {
    e.preventDefault();

    axios.get("http://crime.test/api/guess/auth/hello").then(response => {
        //UserObject.setNameAndToken(response.data.name,response.data.token);
        //alert(UserObject.getName());
        window.location.reload();
    }).catch(function(error) {
        console.log(error);
    });
}



    return (
            <>
                Test me Daddy

                <form onSubmit={handleSubmit}>
                    <button type="submit">Submit</button>
                </form>

                <form onSubmit={authTest}>
                    <button type="submit">Test Auth</button>
                </form>

                { UserObject.getName() }
            </>
        );
}
