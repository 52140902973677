import React, { useState } from "react";
import axios from "axios";
import UserObject from "../../objects/UserObject";
import ServerHandler from "../../objects/ServerHandler";

export function Login() {
    function accountCreate(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            username: { value: string };
            password: { value: string };
        };

        axios.post(ServerHandler.host()+"/auth/login",
            {
                username: target.username.value,
                password: target.password.value
            }).then(response => {

            if (typeof response.data.name == "string") {
                UserObject.setUserPackage(response);
                window.location.href = '/';
            } else {
                alert(response.data.error);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }


    if (UserObject.getAuthed()) {
        return (<>You are already logged in.</>);
    }

    return (
        <>
            <h1>Log in</h1>
            <form onSubmit={accountCreate}>
                <div>
                    <label>
                        Username:
                        <input className="blockinput" type="name" name="username" />
                    </label>
                </div>
                <div>
                    <label>
                        Password:
                        <input className="blockinput" type="password" name="password" />
                    </label>
                </div>

                <button type="submit">Log in</button>
            </form>
        </>
    );
}
