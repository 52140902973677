import React, { useState } from "react";
import UserObject from "../../objects/UserObject";
import {Navigate} from "react-router-dom";


export function Logout() {

    UserObject.unsign();
    window.location.href = '/';

    return (
        <>
            Deauthed
        </>
    );
}
