import { getCookie, setCookie, removeCookie } from 'typescript-cookie'

type CaseItem = {
    id : string;
    name: string;
}

var CaseObject = (function() {
    let invite_id : string | undefined;
    let active_case : CaseItem | undefined;

    var getCaseId = function() {
        return getCookie('invite_id') ;    // Or pull this from cookie/localStorage
    };
    var getUserAuth = function() {
        return {headers:{
                case: getCookie('invite_id')
            }};
    };

    var setCaseInviteId = function(new_id : string | undefined) {
        invite_id = new_id;
        setCookie('invite_id', invite_id);
    };

    var casePicked = function() {
        let usertoken : string | undefined = getCookie('invite_id');
        return typeof usertoken == "string" && usertoken.length > 0;
    }

    var unsign = function() {
        invite_id = undefined;
        //propTypes = { case: "Hello" };
        removeCookie('invite_id');
    }

    return {
        getCaseId: getCaseId,
        setCaseInviteId: setCaseInviteId,
        casePicked: casePicked,
        getUserAuth: getUserAuth,
        unsign : unsign
    }

})();

export default CaseObject;